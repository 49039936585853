import { HttpContentType } from '../enums/ApiRequestConstants'
import { getAsync } from '../helpers/apiRequest'
import { IGetBambooChatRequestModel } from '../models/api/ICreateBambooChatModel'
import { IGetFileAttachmentRequestModel } from '../models/api/IGetFileAttachmentModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export const getAttachment = TokenExpiryWrapper(
  async (request: IGetFileAttachmentRequestModel): Promise<IResponse<Blob>> =>
    getAsync<IGetFileAttachmentRequestModel, Blob>(
      `/v1/file/chat/${request.chatId}/${request.questionId}/${request.fileId}`,
      request,
      HttpContentType.BLOB,
    ),
  [],
  null,
)

export const getBambooFile = TokenExpiryWrapper(
  async (request: IGetBambooChatRequestModel): Promise<IResponse<Blob>> =>
    getAsync<IGetBambooChatRequestModel, Blob>(`/v1/file/bamboo/${request.chatId}`, request, HttpContentType.BLOB),
  [],
  null,
)
