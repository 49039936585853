import { FC, useState, useContext } from 'react'
import { Pill } from '@aurecon-creative-technologies/styleguide'
import { ThemeContext } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeContext'
import { ThemeEnum } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeSwitcherEnum'

import { IQuestion } from '../models/IQuestionModels'

// import Style from '../styles/FocusTags.module.sass'

const FOCUS_LIMIT = 1

interface IFocusTagsProps {
  question: IQuestion
}

const FocusTags: FC<IFocusTagsProps> = (props) => {
  const [showAllFocus, setShowAllFocus] = useState(false)
  const { question } = props
  const { theme } = useContext(ThemeContext)

  if (!question.focus) return null

  return (
    <>
      {question.focus?.map((focus, index) => {
        if (index === FOCUS_LIMIT && !showAllFocus)
          return (
            <Pill
              key='more'
              colour={theme === ThemeEnum.LIGHT ? 6 : 3}
              size='small'
              onClick={() => setShowAllFocus(true)}
            >
              More
            </Pill>
          )

        if (index > FOCUS_LIMIT && !showAllFocus) return null

        const label = `${focus.type} / ${focus.value}`
        return (
          <Pill key={label} colour={theme === ThemeEnum.LIGHT ? 5 : 3} size='small'>
            {focus.label || focus.type} / {focus.value}
          </Pill>
        )
      })}
      {showAllFocus && (
        <Pill colour={theme === ThemeEnum.LIGHT ? 6 : 3} size='small' onClick={() => setShowAllFocus(false)}>
          Less
        </Pill>
      )}
    </>
  )
}

export default FocusTags
