import { FC, useEffect, useRef, useState } from 'react'
import { Button, FormInput, useToast } from '@aurecon-creative-technologies/styleguide'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import {
  ChatSession,
  FocusOnInput,
  LoadingAnswer,
  QuestionFile,
  QuestionInput,
  ScrollChat,
  SelectedFocus,
  ShowExtendedInput,
  ShowPreparingChat,
} from '../stores/AppStore'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatTypeEnum, ChatTypeInput, ChatTypeStream, ChatTypeToPath, ChatTypeToPrompt } from '../enums/ChatTypeEnum'
import { RatingEnum } from '../enums/RatingEnum'
import { STREAM_SEPERATOR, TEMP_ROW_KEY, UI_WIDTH_COLLAPSE } from '../config/config'
import { IChatSession, IQuestion } from '../models/IQuestionModels'
import { getQuestionResponse, getQuestionStream } from '../api/QuestionService'
import { isJsonString } from '../helpers/json'
import { delay } from '../helpers/utils'
import { createChat, updateChat } from '../api/ChatService'

import Style from '../styles/SubmitButton.module.sass'

const EMPTY_QUESTION = {
  success: false,
  answers: [],
  sources: [],
  rating: RatingEnum.NO_RATING,
  edited: false,
  feedback: null,
  feedbackType: null,
  updatedAt: null,
  createdAt: null,
  loading: true,
  focus: null,
  fileName: null,
  fileName1: null,
  fileName2: null,
}

const ENTER_KEY = 'Enter'
const STOP_MESSAGE = 'Generating stopped!'
const HISTORY_START = 0

interface ISubmitButtonProps {
  chatType: number
  fileUploadRef: React.RefObject<HTMLInputElement>
  imageUploadRef: React.RefObject<HTMLInputElement>
}

let controller = new AbortController()

const SubmitButton: FC<ISubmitButtonProps> = (props) => {
  const [questionInput, setQuestionInput] = useRecoilState(QuestionInput)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [questionFile, setQuestionFile] = useRecoilState(QuestionFile)
  const [focusOnInput, setFocusOnInput] = useRecoilState(FocusOnInput)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)
  const selectedFocus = useRecoilValue(SelectedFocus)
  const setLoadingAnswer = useSetRecoilState(LoadingAnswer)
  const setScrollChat = useSetRecoilState(ScrollChat)
  const setShowPreparingChat = useSetRecoilState(ShowPreparingChat)
  const [history, setHistory] = useState(0)
  const [shift, setShift] = useState(false)
  const { addToast } = useToast()

  const textInputRef = useRef<HTMLInputElement>(null)

  const { chatType, fileUploadRef, imageUploadRef } = props

  useEffect(() => {
    if (!chatSession) return
    setHistory(chatSession.questions.length - 1)
  }, [chatSession])

  useEffect(() => {
    setTimeout(() => textInputRef.current?.focus(), 0)
  }, [focusOnInput])

  useEffect(() => {
    if (!textInputRef.current) return

    const keyDownEvent = (event: KeyboardEvent) => {
      if (event.key === ENTER_KEY && !event.shiftKey) event.preventDefault()

      setShift(event.shiftKey)

      if (!chatSession) return

      if (event.key === 'ArrowUp' && event.shiftKey) {
        event.preventDefault()
        const newHistory = history === HISTORY_START ? HISTORY_START : history - 1

        const question = chatSession.questions[newHistory].question
        setQuestionInput(question)
        setHistory(newHistory)
      }

      if (event.key === 'ArrowDown' && event.shiftKey) {
        event.preventDefault()
        const valid = history < chatSession.questions.length - 1
        const newHistory = history + 1

        const max = newHistory > chatSession.questions.length
        setHistory(max ? history : newHistory)

        if (valid) {
          const question = chatSession.questions[newHistory].question
          setQuestionInput(question)
          return
        }

        setQuestionInput('')
      }
    }

    const keyUpEvent = (event: KeyboardEvent) => {
      setShift(event.shiftKey)
    }

    const el = textInputRef.current
    el.addEventListener('keydown', keyDownEvent)
    el.addEventListener('keyup', keyUpEvent)

    return () => {
      el.removeEventListener('keydown', keyDownEvent)
      el.removeEventListener('keyup', keyUpEvent)
    }
  }, [chatSession, history, setQuestionInput])

  useEffect(() => {
    if (!textInputRef.current) return

    textInputRef.current.style.height = `0px`
    const scrollHeight = textInputRef.current.scrollHeight
    textInputRef.current.style.height = `${scrollHeight + 2}px`
  }, [questionInput])

  const prepareChat = async () => {
    let newChatSession: IChatSession

    // New chat
    if (!chatSession) {
      setShowPreparingChat(true)

      const responseChat = await createChat({ firstQuestion: questionInput, type: chatType })

      if (!responseChat?.data) {
        setShowPreparingChat(false)
        addToast({
          type: 'error',
          message: `New session can't be initiated. Please try again.`,
          timeout: 5000,
        })
        return null
      }

      newChatSession = {
        chatId: responseChat.data.id,
        questions: [],
        type: chatType,
      }

      setShowPreparingChat(false)
    } else {
      newChatSession = { ...chatSession }
      const response = await updateChat({ chatId: chatSession.chatId })

      if (!response?.data) {
        addToast({
          type: 'error',
          message: `Can't submit a question. Please try again.`,
          timeout: 5000,
        })
        return null
      }
    }

    return newChatSession
  }

  const handleResponse = async (
    newChatSession: IChatSession,
    newQuestion: Pick<IQuestion, 'chatId' | 'question' | 'focus'>,
    tempQuestion: IQuestion,
  ) => {
    const response = await getQuestionResponse({ ...newQuestion, type: chatType, file: questionFile, controller })

    if (response.response) {
      setChatSession({
        ...newChatSession,
        questions: [...newChatSession.questions, response.response],
      })
    } else {
      if (response.error === STOP_MESSAGE)
        addToast({
          type: 'info',
          message: 'Generating stopped',
          timeout: 5000,
        })
      else
        addToast({
          type: 'error',
          message: 'Error processing question.',
          timeout: 5000,
        })
      setChatSession({
        ...newChatSession,
        questions: [...newChatSession.questions, { ...tempQuestion, loading: false }],
      })
    }

    setScrollChat((s) => s + 1)

    setFocusOnInput((value) => value + 1)
  }

  const handleStreamResponse = async (
    newChatSession: IChatSession,
    newQuestion: Pick<IQuestion, 'chatId' | 'question' | 'focus'>,
    tempQuestion: IQuestion,
  ) => {
    const responseStream = await getQuestionStream({
      ...newQuestion,
      type: chatType,
      file: questionFile,
      controller,
    })

    if (responseStream.error || !responseStream.response?.body) {
      addToast({
        type: 'error',
        message: responseStream.error ?? 'Error processing question.',
        timeout: 5000,
      })
      setChatSession({
        ...newChatSession,
        questions: [...newChatSession.questions, { ...tempQuestion, loading: false }],
      })
      setLoadingAnswer(false)
      return
    }

    const reader = responseStream.response.body.getReader()
    const decoder = new TextDecoder()
    let text = ''
    let responseQuestion: IQuestion | null = null

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await delay(100)

      let value: undefined | Uint8Array
      let done = false

      try {
        const read = await reader.read()
        value = read.value
        done = read.done
      } catch (err) {
        setChatSession({
          ...newChatSession,
          questions: responseQuestion
            ? [...newChatSession.questions, { ...responseQuestion, loading: false, finishReason: 'User abort' }]
            : [...newChatSession.questions],
        })
        break
      }

      if (done || controller.signal.aborted) break

      const decodedChunk = decoder.decode(value, { stream: true })
      text += decodedChunk
      const chunks = text.split(STREAM_SEPERATOR).reverse()

      try {
        for (const chunk of chunks) {
          if (chunk === '' || !isJsonString(chunk)) continue

          const cleanChunk = chunk.replace(/\n/g, '\\n')
          responseQuestion = JSON.parse(cleanChunk)
          break
        }

        if (!responseQuestion) continue

        setChatSession({
          ...newChatSession,
          questions: [...newChatSession.questions, responseQuestion],
        })
        setScrollChat((s) => s + 1)
        setFocusOnInput((value) => value + 1)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleQuestionSubmit = async (key?: string) => {
    if ((key && key !== ENTER_KEY) || shift) return
    if (!questionInput.trim()) return

    setLoadingAnswer(true)

    controller = new AbortController()

    const newChatSession = await prepareChat()
    if (!newChatSession) {
      resetInput()
      return
    }

    const newQuestion = {
      chatId: newChatSession.chatId,
      question: questionInput.trim(),
      focus: ChatTypeInput[props.chatType].focus ? selectedFocus : [],
    }

    const tempQuestion = {
      ...newQuestion,
      ...EMPTY_QUESTION,
      rowKey: TEMP_ROW_KEY,
    }

    setShowExtendedInput(false)

    setChatSession({
      ...newChatSession,
      questions: [...newChatSession.questions, tempQuestion],
    })
    setScrollChat((s) => s + 1)

    location.hash = `#/${ChatTypeToPath[chatType]}/${newChatSession.chatId}`

    if (ChatTypeStream[chatType]) handleStreamResponse(newChatSession, newQuestion, tempQuestion)
    else handleResponse(newChatSession, newQuestion, tempQuestion)

    setHistory(newChatSession.questions.length + 1)
    resetInput()
  }

  const resetInput = () => {
    setQuestionFile(null)
    setQuestionInput('')
    setLoadingAnswer(false)
    setScrollChat((s) => s + 1)

    if (fileUploadRef.current) fileUploadRef.current.value = ''
    if (imageUploadRef.current) imageUploadRef.current.value = ''
  }

  const stopGenerating = () => {
    console.log('Aborting...')
    controller.abort()
    resetInput()
  }

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })

  const loading = chatSession?.questions.some((q) => !!q.loading)
  const homePage = !chatSession?.questions.length
  const prompts = ChatTypeToPrompt[chatType]

  const placeholderText = homePage ? prompts.start : prompts.follow
  const placeholder = loading ? prompts.loading : placeholderText

  const submitButtonProps = isDesktop
    ? { type: 'primary' as 'icon-square' | 'primary', label: loading ? 'Stop' : 'Submit' }
    : { type: 'icon-square' as 'icon-square' | 'primary', icon: loading ? 'stop' : 'send' }

  const submitButtonClasses = classNames({
    [Style.submitButton]: true,
    [Style.chatGpt]: chatType === ChatTypeEnum.GPT,
    [Style.chatRecall]: chatType === ChatTypeEnum.RECALL,
  })

  return (
    <>
      <FormInput
        placeholder={placeholder}
        cssClass={Style.input}
        value={questionInput}
        onChange={setQuestionInput}
        disabled={loading}
        onKeyDown={(key) => handleQuestionSubmit(key)}
        multiline
        ref={textInputRef}
      />
      <span>
        <Button
          {...submitButtonProps}
          cssClass={submitButtonClasses}
          onClick={loading ? stopGenerating : () => handleQuestionSubmit()}
        />
      </span>
    </>
  )
}

export default SubmitButton
