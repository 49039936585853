import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Pill, useToast, FileUpload } from '@aurecon-creative-technologies/styleguide'
import {
  dropzoneHeight,
  dropzoneWidth,
  sizeLimit,
  fileUnits,
  sizeLimitMessage,
  fileTypes,
  allowedFileExtensionsMessage,
  dropzoneTooltip,
  dropzoneLabel,
  fileActionRenderer,
} from '../config/fileUploadConfig'
import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'

import ChatBamboo from './ChatBamboo'
import { processBambooFile } from '../api/BambooChatService'
import ChatSwitcher from '../components/ChatSwitcher'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { ChatType, QuestionFile } from '../stores/AppStore'
import { useSetRecoilState } from 'recoil'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { v4 } from 'uuid'
import { catchSocketEvent, disconnectSocket } from '../api/Socket'
import { SocketIoEnum } from '../enums/SocketIoEnum'
import { IGenericModel } from '../models/api/IBambooChatModel'
import { useMediaQuery } from 'react-responsive'
import { UI_FULL_WIDTH, UI_WIDTH_COLLAPSE } from '../config/config'
import PDFViewer from '../components/PDFViewer'

import Style from '../styles/Bamboo.module.sass'

const ChatBambooHome: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Bamboo Home' })
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const [chatId, setChatId] = useState('')
  const [loadingRes, setLoadingRes] = useState(false)
  const { addToast } = useToast()
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [openChat, setOpenChat] = useState(false)
  const [content, setContent] = useState('')

  useEffect(() => {
    setChatType(ChatTypeEnum.BAMBOO)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  useEffect(() => {
    return () => {
      disconnectSocket(SocketIoEnum.BAMBOO_FILE_PROCESSED)
    }
  }, [])

  const updateFileContent = (res: IGenericModel) => {
    setContent(res.fileContent)
  }

  const onNextClicked = async () => {
    if (!uploadedFile) return
    setLoadingRes(true)

    const newChatId = v4()

    catchSocketEvent(SocketIoEnum.BAMBOO_FILE_PROCESSED, newChatId, updateFileContent)

    const response = await processBambooFile({ file: uploadedFile, chatId: newChatId })

    if (!response?.data) {
      addToast({
        type: 'error',
        message: `New session can't be initiated. Please try again.`,
        timeout: 5000,
      })
      setLoadingRes(false)
      return
    }

    addToast({
      type: 'success',
      message: 'New bamboo session has been set.',
      timeout: 5000,
    })

    setLoadingRes(false)
    setChatId(newChatId)
    setOpenChat(true)
  }

  const handleFileChange = (files: File[]) => {
    if (files.length) {
      setUploadedFile(files[0])
      return
    }
    setUploadedFile(undefined)
  }

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const needFullWidth = useMediaQuery({ minWidth: UI_FULL_WIDTH })

  const viewerHeight = useMemo(() => {
    if (needFullWidth) return 400
    if (isDesktop) return 250
    return 150
  }, [isDesktop, needFullWidth])

  const renderPDFViewer = (renderFile?: File) => {
    return (
      <PDFViewer
        file={URL.createObjectURL(renderFile as File)}
        showOnlyAsPreview={false}
        height={viewerHeight}
        viewerId={`viewer-${renderFile?.name || 'bamboo'}`}
      />
    )
  }

  const fileUploadProps = {
    dropzoneLabel: dropzoneLabel,
    allowedFileExtensions: fileTypes,
    allowedFileExtensionsMessage: allowedFileExtensionsMessage,
    dropzoneTooltip: dropzoneTooltip,
    onChange: handleFileChange,
    fileRenderer: renderPDFViewer,
    sizeLimitMessage: sizeLimitMessage,
    fileActionRenderer: fileActionRenderer,
    height: dropzoneHeight,
    width: dropzoneWidth,
  }

  if (openChat) return <ChatBamboo chatId={chatId} file={uploadedFile} content={content} />

  return (
    <Page menu contentWrapper>
      <h1 className={Style.headerTitle}>Bamboo</h1>
      <div className={Style.experimental}>
        <Pill colour={13} icon='science' size='small'>
          Experimental
        </Pill>
      </div>

      <ChatSwitcher activeChat={ChatTypeEnum.BAMBOO} />

      <div className={Style.headerText}>
        <p>Bamboo allows you to tease structured information out of unstructured raw data</p>
      </div>
      <div className={Style.uploadArea}>
        <h3>Upload Files</h3>
        <p className={Style.uploadSubtitle}>
          By uploading a PDF, and specifying the categories of information you need, Bamboo can read through your
          document and provide structured data.
        </p>
      </div>
      <FileUpload {...fileUploadProps} sizeLimit={[sizeLimit, fileUnits]} height={`${viewerHeight}px`} />
      <div className={Style.bambooFooter}>
        <Button
          label='Next'
          type='primary'
          cssClass={Style.nextButton}
          disabled={!uploadedFile || loadingRes}
          onClick={onNextClicked}
        />
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatBambooHome
