import { FC, useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import { getChatQuestions } from '../api/QuestionService'
import { ChatSession, ChatType, NotFound, ScrollChat } from '../stores/AppStore'
import Page from '../components/Page'
import ChatQuestion from '../components/ChatQuestion'
import ChatAnswer from '../components/ChatAnswer'
import ChatNotFound from '../components/ChatNotFound'
import AnswerFeedbackModal from '../components/modals/AnswerFeedbackModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import AnswerEditModal from '../components/modals/AnswerEditModal'
import EditHistoryModal from '../components/modals/EditHistoryModal'
import FileDownloadModal from '../components/modals/FileDownloadModal'
import ChatSwitcher from '../components/ChatSwitcher'
import Style from '../styles/Home.module.sass'

const ChatRecall: FC = () => {
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const setScrollChat = useSetRecoilState(ScrollChat)
  const setChatType = useSetRecoilState(ChatType)
  const contentsRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const { chatId } = useParams()

  useEffect(() => {
    setChatType(ChatTypeEnum.RECALL)

    return () => setChatType(null)
  }, [setChatType])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = chatId || ''
      const questions = await getChatQuestions({ chatId: id })

      if (!questions?.data) {
        setLoading(false)
        setNotFound(true)
        setChatSession(null)
        return
      }

      setChatSession({
        chatId: id,
        questions: questions.data,
        type: ChatTypeEnum.RECALL,
      })
      setLoading(false)
      setNotFound(false)

      setTimeout(() => {
        setScrollChat((s) => s + 1)
      }, 1000)
    }

    if (chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [chatSession, chatId, setChatSession, setNotFound, setScrollChat])

  if (appInsights) appInsights.trackPageView({ name: 'Recall Chat' })

  if (loading)
    return (
      <Page menu contentWrapper>
        <h1>Recall Chat</h1>
        <Loader label='Loading chat...' />
      </Page>
    )

  return (
    <Page menu contentWrapper contentsRef={contentsRef}>
      <h1 className={Style.chatTitle}>Recall Chat</h1>
      <ChatSwitcher activeChat={ChatTypeEnum.RECALL} />
      {notFound && <ChatNotFound />}
      {chatSession?.questions.map((question) => {
        return (
          <div key={question.rowKey}>
            <ChatQuestion question={question} />
            <ChatAnswer question={question} />
          </div>
        )
      })}
      <AnswerFeedbackModal />
      <AnswerEditModal />
      <EditHistoryModal />
      <FileDownloadModal />
    </Page>
  )
}

export default ChatRecall
