import { FC, useEffect, useState } from 'react'
import { FormInput, IButtonProps, Modal, useToast } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState } from 'recoil'
import { ChatSession, CurrentQuestion, ShowEditModal } from '../../stores/AppStore'
import { updateQuestion } from '../../api/QuestionService'

const AnswerEditModal: FC = () => {
  const [currentQuestion, setCurrentQuestion] = useRecoilState(CurrentQuestion)
  const [showEditModal, setShowEditModal] = useRecoilState(ShowEditModal)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [editText, setEditText] = useState('')
  const [dirty, setDirty] = useState(false)
  const { addToast } = useToast()

  useEffect(() => {
    if (!currentQuestion?.answers.length) return

    const lastAnswer = currentQuestion?.answers.slice(-1)[0]

    setEditText(lastAnswer.answer)
    setDirty(false)
  }, [currentQuestion?.answers])

  const handleModalClose = () => {
    setEditText('')
    setCurrentQuestion(null)
    setShowEditModal(false)
  }

  const handleTextChange = (text: string) => {
    setEditText(text)
    setDirty(true)
  }

  const handleSaveAnswer = async () => {
    if (!currentQuestion || !chatSession) return

    const answers = [...currentQuestion.answers, { answer: editText, createdAt: '' }]

    const result = await updateQuestion({
      chatId: currentQuestion.chatId,
      rowKey: currentQuestion.rowKey,
      edited: true,
      answers,
    })

    if (!result?.success) {
      addToast({
        type: 'error',
        message: `Can't update the answer. Please try again.`,
        timeout: 5000,
      })
      return
    }
    addToast({
      type: 'success',
      message: `Updated answer saved.`,
      timeout: 5000,
    })

    const updatedQuestions = chatSession.questions.map((q) => {
      if (q.rowKey !== currentQuestion.rowKey) return q

      return {
        ...currentQuestion,
        edited: true,
        answers,
      }
    })

    setChatSession({
      ...chatSession,
      questions: updatedQuestions,
    })
    handleModalClose()
  }

  const modalActions = [
    {
      label: 'Discard',
      type: 'secondary',
      onClick: handleModalClose,
    },
    {
      label: 'Update',
      onClick: handleSaveAnswer,
      disabled: !editText.trim() || !dirty,
    },
  ] as IButtonProps[]

  return (
    <Modal isShowing={showEditModal} onClose={handleModalClose} actions={modalActions} size='large'>
      <h2>Edit answer</h2>
      <p>
        Please edit answer, so it is correctly aswering your question
        <br />
        <i>Please note</i>: The answer text uses{' '}
        <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel='noreferrer'>
          markdown
        </a>{' '}
        syntax.
      </p>
      <FormInput
        multiline
        placeholder='Your answer...'
        value={editText}
        onChange={handleTextChange}
        cssClass='edit-input'
      />
    </Modal>
  )
}

export default AnswerEditModal
