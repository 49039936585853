import { FC, useMemo, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { FileUpload } from '@aurecon-creative-technologies/styleguide'
import {
  dropzoneHeight,
  dropzoneWidth,
  sizeLimit,
  fileUnits,
  sizeLimitMessage,
  fileTypes,
  allowedFileExtensionsMessage,
  dropzoneTooltip,
  dropzoneLabel,
  fileActionRenderer,
} from '../../config/fileUploadConfig'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import PDFViewer from '../PDFViewer'

import { useMediaQuery } from 'react-responsive'
import { UI_FULL_WIDTH, UI_WIDTH_COLLAPSE } from '../../config/config'

import Style from '../../styles/ManageFileModal.module.sass'

interface IManageFileModalProps {
  open: boolean
  file: File | null
  viewerId?: string
  onClose: () => void
  onUpdate: (file: File) => void
}

const ManageFileModal: FC<IManageFileModalProps> = ({ open, file, onClose, onUpdate }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const fullScreen = useRecoilValue(FullScreen)

  const labelYes = 'Update'

  const onModalClosed = () => {
    onClose()
  }

  const handleUpdate = () => {
    if (uploadedFile) {
      onUpdate(uploadedFile)
    }
  }

  const handleFileChange = (files: File[]) => {
    const newFile = files.length ? files[0] : null
    setUploadedFile(newFile)
  }
  const renderPDFViewer = (renderFile?: File | string) => {
    return (
      <PDFViewer
        file={URL.createObjectURL(renderFile as File)}
        showOnlyAsPreview={false}
        height={viewerHeight}
        viewerId={`viewer-manage-${name}`}
      />
    )
  }

  const fileUploadProps = {
    initialFiles: file ? [new File([file], file.name, { type: file.type })] : [],
    dropzoneLabel: dropzoneLabel,
    allowedFileExtensions: fileTypes,
    allowedFileExtensionsMessage: allowedFileExtensionsMessage,
    dropzoneTooltip: dropzoneTooltip,
    onChange: handleFileChange,
    fileRenderer: renderPDFViewer,
    sizeLimitMessage: sizeLimitMessage,
    fileActionRenderer: fileActionRenderer,
    height: dropzoneHeight,
    width: dropzoneWidth,
  }

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const needFullWidth = useMediaQuery({ minWidth: UI_FULL_WIDTH })

  const viewerHeight = useMemo(() => {
    if (needFullWidth) return 400
    if (isDesktop) return 250
    return 150
  }, [isDesktop, needFullWidth])

  return (
    <RecallModal
      isShowing={open}
      onClose={onModalClosed}
      chatType={ChatTypeEnum.BAMBOO}
      disabled={!uploadedFile}
      onSave={handleUpdate}
      labelYes={labelYes}
      size={fullScreen ? 'medium' : 'small'}
    >
      <div className={Style.uploadArea}>
        <h3>Upload Files</h3>
        <p className={Style.uploadSubtitle}>
          By uploading a PDF, and specifying the categories of information you need, Bamboo can read through your
          document and provide structured data.
        </p>
        <FileUpload {...fileUploadProps} sizeLimit={[sizeLimit, fileUnits]} height={`${viewerHeight}px`} />
      </div>
    </RecallModal>
  )
}

export default ManageFileModal
