import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { Modal } from '@aurecon-creative-technologies/styleguide'

import { ShowPreparingChat } from '../../stores/AppStore'

const PreparingChatModal: FC = () => {
  const showPreparingChat = useRecoilValue(ShowPreparingChat)

  const handleModalClose = () => {
    // do nothing
  }

  return (
    <Modal isShowing={showPreparingChat} isCloseButton={false} onClose={handleModalClose}>
      <h2>Processing...</h2>
      <p>Preparing new chat session.</p>
    </Modal>
  )
}

export default PreparingChatModal
