import { FC, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { Button, Tooltip } from '@aurecon-creative-technologies/styleguide'
import { useMediaQuery } from 'react-responsive'

import { FullScreen } from '../stores/AppStore'
import { COOKIE_FULLSCREEN, UI_FULL_WIDTH } from '../config/config'

import Style from '../styles/FullScreenSwitch.module.sass'

const FullScreenSwitch: FC = () => {
  const [fullScreen, setFullScreen] = useRecoilState(FullScreen)
  const needFullWidth = useMediaQuery({ minWidth: UI_FULL_WIDTH })

  useEffect(() => {
    const value = localStorage.getItem(COOKIE_FULLSCREEN)
    setFullScreen(value === 'yes')
  }, [setFullScreen])

  const toggleFullScreen = () => {
    setFullScreen((value) => {
      localStorage.setItem(COOKIE_FULLSCREEN, value ? 'no' : 'yes')
      return !value
    })
  }

  const button = (
    <Button
      type='icon-round-secondary'
      icon={fullScreen ? 'fullscreen_exit' : 'fullscreen'}
      onClick={toggleFullScreen}
      cssClass={Style.headerButton}
      size='small'
      disabled={!needFullWidth}
    />
  )

  return (
    <div className={Style.switch}>
      {needFullWidth ? <Tooltip show={<p>Toggle full width</p>}>{button}</Tooltip> : button}
    </div>
  )
}

export default FullScreenSwitch
