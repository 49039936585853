import { FC, useState } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { BambooPropertyEnum } from '../../enums/BambooPropertyEnum'
import { IBambooPropertyModel } from '../../models/IBambooPropertyModel'
import LimitedCharactersLeft from '../common/LimitedCharacters'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import RecallCheckbox from '../common/RecallCheckbox'
import RecallDropdown from '../common/RecallDropdown'
import { v4 } from 'uuid'
import { BambooPropertyModalFields, bambooPropertyModalSchema } from '../../validators/bambooPropertyModalValidator'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'

import Style from '../../styles/BambooPropertyModal.module.sass'

const maxPropNameLength = 500
const maxDescLength = 1000

interface IPropertyModalProps {
  open: boolean
  selectedProperty: IBambooPropertyModel
  properties: IBambooPropertyModel[]
  onSave: (data: IBambooPropertyModel) => void
  onClose: () => void
}

const BambooPropertyModal: FC<IPropertyModalProps> = ({ open, selectedProperty, properties, onSave, onClose }) => {
  const [property, setProperty] = useState<IBambooPropertyModel>({
    ...selectedProperty,
    type: selectedProperty.type ?? BambooPropertyEnum.STRING,
    required: selectedProperty.required ?? true,
  })
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)

  const handleModalClose = () => {
    setProperty((val) => ({ ...val, name: '', description: '', type: BambooPropertyEnum.STRING, required: false }))
    setErrors({})
    onClose()
  }

  const validateProperty = (currentProperty: IBambooPropertyModel) => {
    const validationResult = bambooPropertyModalSchema({ properties }).validate(
      { name: currentProperty.name },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const handleValueChange = (field: string, value: string | boolean) => {
    const updatedProperty = { ...property, [field]: value }
    setProperty(updatedProperty)
    const errors = validateProperty(updatedProperty)
    setErrors(errors)
  }

  const handleSaveProperty = () => {
    const errors = validateProperty(property)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    onSave({
      ...property,
      id: property.id || v4(),
      name: property.name.trim(),
    })

    handleModalClose()
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.BAMBOO}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!property.name || !!Object.keys(errors).length}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={selectedProperty.id ? 'Update' : 'Create'}
    >
      <div className={Style.contentWrapper}>
        <h2>{selectedProperty.id ? 'Edit Property' : 'New Property'}</h2>
        <p className={Style.propertyDescription}>
          A Property is an attribute of your entity that you’d like Bamboo to extract information about from the PDF.
          example could be for an “Asset” from a service manual, a property could be the “Type” or “Name”, as well as
          the “Service Frequency”.
        </p>

        <Grid row gap={12}>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder='Enter property name'
              value={property.name}
              onChange={(val) => handleValueChange(BambooPropertyModalFields.name, val)}
              label='Property Name'
              error={errors.name?.[0]}
              chatType={ChatTypeEnum.BAMBOO}
            />
            {!errors.name?.[0] && <LimitedCharactersLeft maxLength={maxPropNameLength} value={property.name} />}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              multiline
              placeholder='Enter property description'
              value={property.description}
              onChange={(val) => handleValueChange(BambooPropertyModalFields.description, val)}
              cssClass={Style.description}
              label='Description'
              multilineLimit={maxDescLength}
              chatType={ChatTypeEnum.BAMBOO}
            />
          </Grid>
          <Grid item xs={12} cssClass={Style.infoRow}>
            <Icon type='info' cssClass={Style.infoIcon} outlined />
            <span>Use the description to help Bamboo understand more about your property</span>
          </Grid>
          <Grid item xs={12}>
            <div className={Style.typeWrapper}>
              <div className={Style.dropdownWrapper}>
                <RecallDropdown
                  open='up'
                  label='Type'
                  items={[
                    { id: BambooPropertyEnum.STRING, label: BambooPropertyEnum.STRING },
                    { id: BambooPropertyEnum.NUMBER, label: BambooPropertyEnum.NUMBER },
                    { id: BambooPropertyEnum.BOOLEAN, label: BambooPropertyEnum.BOOLEAN },
                  ]}
                  selectedItem={property.type}
                  onSelectItem={(val) => {
                    handleValueChange(BambooPropertyModalFields.type, val.toString())
                  }}
                  cssClass={Style.type}
                  placeholder='Select an option'
                  chatType={ChatTypeEnum.BAMBOO}
                />
              </div>
              <div className={Style.checkboxWrapper}>
                <b>Required</b>
                <RecallCheckbox
                  checked={property.required}
                  onChange={(val) => {
                    handleValueChange(BambooPropertyModalFields.required, val)
                  }}
                  chatType={ChatTypeEnum.BAMBOO}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default BambooPropertyModal
