import { FC } from 'react'
import Style from '../../styles/common/LimitedCharacters.module.sass'
import classNames from 'classnames'

export interface ILimitedCharactersLeftProps {
  value?: string
  maxLength: number
  className?: string
}

const LimitedCharactersLeft: FC<ILimitedCharactersLeftProps> = ({ value, maxLength, className }) => {
  return (
    <div className={classNames(Style.charactersLeft, className)}>
      {maxLength - (value ? value.length : 0)} characters left.
    </div>
  )
}

export default LimitedCharactersLeft
