import { FC } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { ReactComponent as Logo } from '../assets/Recall_light.svg'
import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_white.svg'

import { ABOUT_URL, JIRA_SUPPORT_URL } from '../config/config'
import { appInsights } from '../api/AppInsights'
import { AppVersion } from '../stores/AppStore'

import Style from '../styles/Login.module.sass'

interface IState {
  from: { pathname: string }
}

const Login: FC = () => {
  const appVersion = useRecoilValueLoadable(AppVersion)
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const location = useLocation()
  if (appInsights) appInsights.trackPageView({ name: 'Login' })

  if (isAuthenticated) return <Navigate to='/' />

  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  const handleLogin = () => {
    const state = location.state as IState

    if (state?.from?.pathname?.toLowerCase() === '/login') return

    const targetUrl = state ? `/#${state.from.pathname}` : '/'

    loginWithRedirect({
      appState: { targetUrl },
      connection_scope: `offline_access`,
    })
  }

  const version = appVersion.state === 'hasValue' ? appVersion.contents : '0.0.0.0'

  return (
    <div className={`${Style.loginPage} login`}>
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <AureconLogo height={30} />
        </div>
        <div className={Style.content}>
          <h2>Welcome to</h2>
          <Logo className={Style.logo} />
          <p>
            Recall is a new interface to Aurecon's knowledge and expertise, that allows us to quickly and accurately
            retrieve relevant information and answer user queries
          </p>
          <Button type='primary' cssClass='is-white' label='Login' onClick={handleLogin} />
        </div>
        <div className={Style.content} />
        <div className={Style.footer}>
          <Button type='text' cssClass='is-white' label='About' onClick={() => handleButtonClick(`${ABOUT_URL}`)} />
          <Button
            type='text'
            cssClass='is-white'
            label='Contact Us'
            onClick={() => handleButtonClick(`${JIRA_SUPPORT_URL}`)}
          />
          <div className={Style.version}>Version: {version}</div>
        </div>
      </div>
    </div>
  )
}

export default Login
