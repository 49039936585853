import { FC } from 'react'
import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallModal.module.sass'
import classNames from 'classnames'

export interface IRecallModalProps {
  chatType: number
  isShowing: boolean
  disabled: boolean
  onClose: () => void
  onSave: () => void
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  size?: 'small' | 'medium' | 'large'
  labelYes?: string
  labelNo?: string
  hideCloseIcon?: boolean
}

const RecallModal: FC<IRecallModalProps> = (props) => {
  const { chatType, isShowing, disabled, onSave, onClose, children, size, labelYes, labelNo, hideCloseIcon } = props

  const chatTypeClasses = () => {
    return classNames({
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
    })
  }

  const secondaryButtonClasses = () => {
    return classNames(chatTypeClasses(), {
      [Style.actionButtons]: true,
      [Style.secondary]: true,
    })
  }

  const primaryButtonClasses = () => {
    return classNames(chatTypeClasses(), {
      [Style.actionButtons]: true,
      [Style.primary]: true,
    })
  }

  const actions: IButtonProps[] = [
    {
      label: labelNo ?? 'Cancel',
      type: 'secondary',
      onClick: onClose,
      cssClass: secondaryButtonClasses(),
    },
    {
      label: labelYes ?? 'Create',
      onClick: onSave,
      disabled: disabled,
      cssClass: primaryButtonClasses(),
    },
  ]

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <Modal
      cssClass={Style.modalWrapper}
      isShowing={isShowing}
      onClose={handleCloseModal}
      isOverlay={true}
      shouldOverlayClose={false}
      actions={actions}
      size={size}
      isCloseButton={!hideCloseIcon}
    >
      {children}
    </Modal>
  )
}

export default RecallModal
