import { Icon, Button, InfoTooltip, sizeUnits } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/Bamboo.module.sass'
import PDFViewer from '../components/PDFViewer'

//File Upload Component Properties
export const dropzoneHeight = '400px'
export const dropzoneWidth = '100%'
export const sizeLimit = 20
export const fileUnits = sizeUnits.MegaByte
export const sizeLimitMessage = ['File must not exceed ', sizeLimit, fileUnits.label].join('')
export const fileTypes = ['.pdf']
export const allowedFileExtensionsMessage = `Allowed file types: ${fileTypes.join(
  ', ',
)}. Please upload files only in these formats.`

export const dropzoneTooltip = (
  <span className='tooltip'>
    <InfoTooltip
      show={
        <span style={{ textAlign: 'left' }}>
          <div>Supported file types: </div>
          <div>{fileTypes?.join(', ')}</div>
        </span>
      }
    />{' '}
    Supported file types: {fileTypes?.map((type) => type.replace('.', '').toUpperCase()).join(', ')} (max {sizeLimit}
    {fileUnits.label.toLowerCase()})
  </span>
)

export const dropzoneLabel = (
  <div className={Style.dropzoneLabelContainer}>
    <div className={Style.dropzoneLabelIconContainer}>
      <Icon size={'36px'} type='picture_as_pdf' outlined cssClass='fileUploadIcon' />
    </div>
    <div className={Style.dropzoneLabelTextContainer}>
      Drop files to upload or <span className='browse'>browse</span>
    </div>
  </div>
)

export const fileActionRenderer = (file: File, removeFile: (file: File) => void) => (
  <div className='file-uploader-delete-container'>
    <Button
      icon='delete'
      size='extra small'
      type='icon-round'
      cssClass='file-uploader-delete'
      onClick={() => removeFile(file)}
    />
  </div>
)

export const renderPDFViewer = (file: File) => {
  return (
    <PDFViewer
      file={URL.createObjectURL(file)}
      showOnlyAsPreview={false}
      height={398}
      viewerId={`viewer-${file.name}`}
    />
  )
}
