import { FC, useEffect } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { useSetRecoilState } from 'recoil'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatType, QuestionFile, QuestionInput } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'

const SUGGESTIONS = [
  `Can you summarize the technologies used in Cooroy Rockfall Protection project?`,
  `What are the key issues we face in Rail projects?`,
  `Write a one paragraph capability statement for our Bridge Engineering capability. Try to add each aspect of our capability in a different bullet point.`,
]

const ChatRecallHome: FC = () => {
  const setQuestionInput = useSetRecoilState(QuestionInput)
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)

  if (appInsights) appInsights.trackPageView({ name: 'Recall Chat Home' })

  useEffect(() => {
    setChatType(ChatTypeEnum.RECALL)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  const handleSuggestion = (value: string) => {
    setQuestionInput(value)
  }

  return (
    <Page menu contentWrapper>
      <h1 className={Style.chatTitle}>Recall Chat</h1>
      <ChatSwitcher activeChat={ChatTypeEnum.RECALL} />
      <p>
        Recall is a new interface to some of Aurecon's knowledge and expertise, that allows us to quickly and accurately
        retrieve relevant information and answer user queries.
      </p>
      <p>
        Find more information on our hive pages{' '}
        <a
          href='https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/AI-at-Aurecon.aspx'
          target='_blank'
          rel='noreferrer'
        >
          here.
        </a>
      </p>
      <p className={Style.note}>
        <i>
          Recall has knowledge from a selection of pdf's from kHUB only, and all queries are internal to Aurecon only.
        </i>
      </p>

      <div className={Style.examples}>
        <h3>Example questions</h3>
        {SUGGESTIONS.map((suggetion) => {
          return (
            <div
              key={suggetion}
              className={Style.question}
              onClick={() => handleSuggestion(suggetion)}
              onKeyDown={() => handleSuggestion(suggetion)}
              role='none'
            >
              <Icon type='format_quote' outlined size='14px' /> {suggetion}
            </div>
          )
        })}
      </div>
      <div className={Style.examples}>
        <h3>Navigation tips</h3>
        <Grid row>
          <div>
            <p>
              <Icon type='keyboard' outlined />
            </p>
          </div>
          <div className={Style.tip}>
            <p>
              Use ENTER to submit query
              <br />
              Use SHIFT + ENTER to add new line to your query
              <br />
              Use SHIFT + ARROW UP / DOWN to bring previous queries
            </p>
          </div>
        </Grid>
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatRecallHome
