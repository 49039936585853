import { FC, useEffect, useState } from 'react'
import { IQuestion } from '../models/IQuestionModels'
import { getAttachment } from '../api/FileService'
import FilePreviewAttachmentFiles from './FilePreviewAttachmentFiles'
import { ResponseData } from '../models/api/IResponse'

interface IChatQuestionAttachmentFilesProps {
  question: IQuestion
  name: string
  fileId: number
}

const ChatQuestionAttachmentFiles: FC<IChatQuestionAttachmentFilesProps> = (props) => {
  const [content, setContent] = useState<string | null>(null)
  const [blob, setBlob] = useState<Blob | null>(null)
  const [isBlobLoading, setIsBlobLoading] = useState<boolean>(true)
  const { question, name, fileId } = props

  useEffect(() => {
    const preview = async () => {
      if (question.loading) return
      setIsBlobLoading(true)
      const blobRes = ResponseData(
        await getAttachment({ chatId: question.chatId, questionId: question.rowKey, fileId }),
      )
      if (!blobRes) {
        setIsBlobLoading(false)
        return
      }

      setBlob(blobRes)

      const reader = new FileReader()
      reader.readAsDataURL(blobRes)
      reader.onloadend = () => {
        try {
          const base64data = reader.result
          setContent(atob(base64data?.toString().replace('data:application/octet-stream;base64,', '') ?? ''))
        } catch {
          console.error(`** Can't process the file.`)
        }
      }
      setIsBlobLoading(false)
    }

    preview()
  }, [fileId, question.chatId, question.loading, question.rowKey])

  return (
    <FilePreviewAttachmentFiles
      name={name}
      blob={blob}
      content={content}
      action='download'
      isDeleted={!blob}
      isLoading={isBlobLoading}
      isAnswering={question.loading}
    />
  )
}

export default ChatQuestionAttachmentFiles
