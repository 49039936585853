import { selector, atom } from 'recoil'
import { getAppFlags, getAppVersion } from '../api/AppService'
import { IChatSession, IFocusItem, IQuestion } from '../models/IQuestionModels'
import { ResponseData } from '../models/api/IResponse'

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.data?.version
  },
})

export const AppFlags = selector({
  key: 'app_flags',
  get: async () => {
    const response = ResponseData(await getAppFlags({}))
    return response || {}
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const QuestionInput = atom({
  key: 'question_input',
  default: '',
})

export const SelectedFocus = atom<IFocusItem[]>({
  key: 'selected_focus',
  default: [],
})

export const ChatSession = atom<IChatSession | null>({
  key: 'chat_session',
  default: null,
})

export const NotFound = atom<boolean>({
  key: 'not_found',
  default: false,
})

export const ShowPreparingChat = atom<boolean>({
  key: 'show_preparing_chat',
  default: false,
})

export const LoadingAnswer = atom<boolean>({
  key: 'loading_answer',
  default: false,
})

export const CurrentQuestion = atom<IQuestion | null>({
  key: 'current_question',
  default: null,
})

export const ShowEditModal = atom<boolean>({
  key: 'show_edit_modal',
  default: false,
})

export const ShowFeedbackModal = atom<boolean>({
  key: 'show_feedback_modal',
  default: false,
})

export const ShowEditHistoryModal = atom<boolean>({
  key: 'show_edit_history_modal',
  default: false,
})

export const ShowDownloadFileModal = atom<boolean>({
  key: 'show_download_file_modal',
  default: false,
})

export const ShowSeeQuestionModal = atom<boolean>({
  key: 'show_see_question_modal',
  default: false,
})

export const ScrollChat = atom({
  key: 'scroll_chat',
  default: 0,
})

export const ShowExtendedInput = atom({
  key: 'show_extended_input',
  default: false,
})

export const FullScreen = atom({
  key: 'full_screen',
  default: true,
})

export const QuestionFile = atom({
  key: 'question_file',
  default: null as File[] | null,
})

export const ChatType = atom({
  key: 'chat_type',
  default: null as number | null,
})

export const FocusOnInput = atom({
  key: 'focus_on_input',
  default: 0,
})
