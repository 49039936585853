export enum actions {
  VIEW_DASHBOARD = 1,
}

export enum Role {
  FeedbackViewer = 'Recall Feedback Viewer',
}

export const permissions = {
  [actions.VIEW_DASHBOARD]: [Role.FeedbackViewer],
}
