import Joi from 'joi'
import { IBambooPropertyModel } from '../models/IBambooPropertyModel'

export const BambooPropertyModalFields = {
  name: 'name',
  description: 'description',
  type: 'type',
  required: 'required',
}

export const bambooPropertyModalSchema = (extProps: { properties: IBambooPropertyModel[] }) => {
  const { properties } = extProps

  return Joi.object({
    [BambooPropertyModalFields.name]: Joi.string()
      .trim()
      .min(1)
      .max(500)
      .required()
      .custom((text, helper) => {
        if (properties?.some((p) => p.name.toLowerCase() === text.toLowerCase().trim()))
          return helper.message({ custom: 'Property name cannot be duplicated in this entity' })

        return true
      })
      .messages({ 'string.empty': 'Property name is required' }),
    [BambooPropertyModalFields.description]: Joi.string().allow('').max(1000),
  })
}
