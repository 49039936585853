import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ChatSession, LoadingAnswer, ShowExtendedInput } from '../stores/AppStore'
import { AppRoute } from '../enums/AppRouteConstants'
import { logAppAccess } from '../helpers/utils'

export const useChangeChat = () => {
  const setChatSession = useSetRecoilState(ChatSession)
  const loadingAnswer = useRecoilValue(LoadingAnswer)
  const setShowExtendedInput = useSetRecoilState(ShowExtendedInput)

  const openRecallChat = () => {
    if (loadingAnswer) return
    setChatSession(null)
    setShowExtendedInput(true)
    logAppAccess('Recall Chat')
    location.hash = `#/${AppRoute.RECALL_CHAT}`
  }

  const openChatGPT = () => {
    if (loadingAnswer) return
    setChatSession(null)
    logAppAccess('Secure Chat GPT')
    location.hash = `#/${AppRoute.GPT_CHAT}`
  }

  const openWinWise = (openNewTab?: boolean) => {
    if (loadingAnswer) return
    setChatSession(null)
    logAppAccess('Winwise')
    const url = 'https://winwise.aurecongroup.digital/'
    if (openNewTab) {
      window.open(url, '_blank', 'noopener')
      return
    }
    location.href = url
  }

  const openCode = () => {
    if (loadingAnswer) return
    setChatSession(null)
    logAppAccess('Code Assistant')
    location.href = `#/${AppRoute.CODE_CHAT}`
  }

  const openBamboo = () => {
    if (loadingAnswer) return
    setChatSession(null)
    logAppAccess('Bamboo Chat')
    location.href = `#/${AppRoute.BAMBOO_CHAT}`
  }

  return {
    openRecallChat,
    openChatGPT,
    openWinWise,
    openCode,
    openBamboo,
  }
}
