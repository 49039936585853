import { FC, useState } from 'react'
import { RecoilRoot } from 'recoil'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'
import { ThemeProvider } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeContext'

import { AppRoute } from './enums/AppRouteConstants'
import Apps from './pages/Apps'
import NotFound from './pages/NotFound'
import Page from './components/Page'
import LoadingScreen from './components/LoadingScreen'
import Consent from './pages/Consent'
import { TermsCookiesModal } from './components/TermsCookiesModal'
import Profile from './pages/Profile'
import History from './pages/History'
import Home from './pages/Home'
import ApiDocs from './pages/ApiDocs'
import Dashboard from './pages/Dashboard'
import ChatRecallHome from './pages/ChatRecallHome'
import ChatRecall from './pages/ChatRecall'
import ChatGptHome from './pages/ChatGptHome'
import ChatGpt from './pages/ChatGpt'
import ChatBambooHome from './pages/ChatBambooHome'
import ChatBamboo from './pages/ChatBamboo'
import ChatCode from './pages/ChatCode'
import { UserGuide } from './pages/UserGuide'
import { ContactUs } from './pages/ContactUs'
import { createAppInsightContext } from './api/AppInsights'

import ErrorGfx from './assets/kitten.svg'

import Style from './styles/App.module.sass'
import PdfDocumentViewer from './pages/PdfDocumentViewer'
import PublicRoutes, { publicRoutes } from './routes/PublicRoutes'

const App: FC = () => {
  const { isLoading, error, isAuthenticated } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)
  const [authContext, setAuthContext] = useState(false)

  if (error) {
    return (
      <div className={Style.errorWrapper}>
        <div className={Style.innerWrapper}>
          <p>Aurecon Recall</p>
          <p>
            <img src={ErrorGfx} alt='Error' />
          </p>
          <p>Oops... Looks like we have an issue...</p>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <RecoilRoot>
        <Page>
          <LoadingScreen text='Loading application...' />
        </Page>
      </RecoilRoot>
    )
  }

  const privateRoutes = [
    <Route key='home' path='/' element={<Home />} />,
    <Route key='profile' path='/profile' element={<Profile />} />,
    <Route key='user-guide' path='/user-guide' element={<UserGuide />} />,
    <Route key='contact' path='/contact' element={<ContactUs />} />,
    <Route key='recall-home' path={`/${AppRoute.RECALL_CHAT}`} element={<ChatRecallHome />} />,
    <Route key='recall-chat' path={`/${AppRoute.RECALL_CHAT}/:chatId`} element={<ChatRecall />} />,
    <Route key='gpt-home' path={`/${AppRoute.GPT_CHAT}`} element={<ChatGptHome />} />,
    <Route key='gpt-chat' path={`/${AppRoute.GPT_CHAT}/:chatId`} element={<ChatGpt />} />,
    <Route
      key='vision-redirect'
      path={`/${AppRoute.VISION_CHAT}`}
      element={<Navigate to={`/${AppRoute.GPT_CHAT}`} replace />}
    />,
    <Route
      key='vision-chat-redirect'
      path={`/${AppRoute.VISION_CHAT}/:chatId`}
      element={<Navigate to={`/${AppRoute.GPT_CHAT}`} replace />}
    />,
    <Route
      key='code-home-redirect'
      path={`/${AppRoute.CODE_CHAT}`}
      element={<Navigate to={`/${AppRoute.GPT_CHAT}`} replace />}
    />,
    <Route key='code-home' path={`/${AppRoute.CODE_CHAT}/:chatId`} element={<ChatCode />} />,
    <Route key='bamboo-home' path={`/${AppRoute.BAMBOO_CHAT}`} element={<ChatBambooHome />} />,
    <Route key='bamboo-chat' path={`/${AppRoute.BAMBOO_CHAT}/:chatId`} element={<ChatBamboo chatId={''} />} />,
    <Route key={AppRoute.PDF_VIEWER} path={`/${AppRoute.PDF_VIEWER}/:filename`} element={<PdfDocumentViewer />} />,
    <Route key='history' path='/history' element={<History />} />,
    <Route key='dashboard' path='/dashboard' element={<Dashboard />} />,
    <Route key='apps' path='/apps' element={<Apps />} />,
    <Route key='api-docs' path='/api-docs/:version' element={<ApiDocs />} />,
  ]

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter>
          <PublicRoutes />
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  if (!authContext) {
    createAppInsightContext()
    setAuthContext(true)
  }

  // In the case with two routes with the same path, private will override public
  return (
    <RecoilRoot>
      <ThemeProvider>
        <ToastProvider>
          <HashRouter>
            <Routes>
              {privateRoutes}
              {publicRoutes}
              <Route path='*' element={<NotFound />} />
            </Routes>
            <TermsCookiesModal />
          </HashRouter>
          <Toast />
        </ToastProvider>
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default App
