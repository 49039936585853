import { FC } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as BambooLogo } from '../../src/assets/Bamboo_logo.svg'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import AppCard from '../components/AppCard'
import { AppColour } from '../enums/AppColourConstants'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useChangeChat } from '../hooks/useChangeChat'

import Style from '../styles/Apps.module.sass'

const Home: FC = () => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo } = useChangeChat()

  if (appInsights) appInsights.trackPageView({ name: 'Apps' })
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)

  return (
    <Page menu contentWrapper contentWrapperWide>
      <h1>AI Powered Apps</h1>

      <div className={Style.cards}>
        <AppCard
          appName='Recall Chat'
          AppIcon={RecallMenuIcon}
          appContents={
            <>
              <p>
                Recall Chat uses Aurecon's knowledge and expertise, allowing us to quickly retrieve relevant information
                and answer Aurecon related queries.
              </p>
              <p>
                Recall Chat has access to some curated PDFs Knowledge @ Aurecon (kHub) as a source of its information,
                and we're continuously expanding it's capability.
              </p>
            </>
          }
          appButtonLabel='Ask Recall Chat'
          buttonColour={AppColour.RECALL_CHAT}
          onClick={openRecallChat}
        />

        <AppCard
          appName='Secure ChatGPT'
          AppIcon={GPTMenuIcon}
          appContents={
            <>
              <p>Secure ChatGPT, hosted by Recall is Aurecon's preferred method for using ChatGPT.</p>
              <p>
                SecureChatGPT allows you to use ChatGPT as you would normally do, while maintaining confidentiality and
                privacy, as your interactions are hosted within Aurecon's digital infrastructure.
              </p>
            </>
          }
          appButtonLabel='Ask Secure ChatGPT'
          buttonColour={AppColour.GPT_CHAT}
          onClick={openChatGPT}
        />

        <AppCard
          appName='WinWise'
          AppIcon={WinWiseLogo}
          appContents={
            <>
              <p>WinWise is a powerful document analysis tool.</p>
              <p>
                WinWise quickly processes Requests for Proposals or Tenders (RFPs & RFTs) and other documents to quickly
                generate a summary, extract key skills and answers to frequently asked questions. It then matches
                relevant people from the data in the CVs on our APEX digital platform.
              </p>
            </>
          }
          appButtonLabel='Open Winwise'
          buttonColour={AppColour.WINWISE}
          onClick={openWinWise}
        />
        {showBambooChat.enabled && (
          <AppCard
            appName='Bamboo'
            AppIcon={BambooLogo}
            appContents={
              <>
                <p>Bamboo allows you to tease structured information out of unstructured raw data.</p>
                <p>
                  By uploading a PDF, and specifying the categories of information you need, Bamboo can read through
                  your document and provide structured data.
                </p>
              </>
            }
            appButtonLabel='Try Bamboo'
            buttonColour={AppColour.BAMBOO_APP_PAGE}
            onClick={openBamboo}
            experimental
          />
        )}
      </div>
    </Page>
  )
}

export default Home
