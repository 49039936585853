import React, { FC, useEffect, useContext } from 'react'
import { useRecoilValue } from 'recoil'
import classNames from 'classnames'
import { Container } from '@aurecon-creative-technologies/styleguide'
import { ThemeContext } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeContext'
import { ThemeEnum } from '@aurecon-creative-technologies/styleguide/lib/helpers/ThemeSwitcherEnum'

import Menu from './Menu'
import PageHeader from './PageHeader'
import QuestionBox from './QuestionBox'
import { ChatType, FullScreen, ScrollChat } from '../stores/AppStore'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from '../hooks/useShowFeature'

import Style from '../styles/Page.module.sass'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

export interface IPageProps {
  pageTitle?: string
  menu?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  contentWrapper?: boolean
  contentWrapperWide?: boolean
  contentsRef?: React.RefObject<HTMLDivElement>
  cssClassName?: string
}

const Page: FC<IPageProps> = (props) => {
  const { theme } = useContext(ThemeContext)
  const chatType = useRecoilValue(ChatType)
  const fullScreen = useRecoilValue(FullScreen)
  const scrollChat = useRecoilValue(ScrollChat)
  const showInfoMessage = useShowFeature(FeatureFlagEnum.ShowInfoMessage)

  useEffect(() => {
    if (!props.contentsRef?.current) return

    props.contentsRef.current.scrollTo({ top: props.contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [props.contentsRef, scrollChat])

  const message = showInfoMessage.client_filters?.find((cf) => cf.name === 'Message')?.parameters?.Banner

  const pageContentsClasses = classNames({
    [Style.pageContents]: true,
    [Style.fullscreen]: fullScreen,
    [Style.pageWideContents]: props.contentWrapperWide,
  })

  const pageClasses = classNames({
    [Style.page]: true,
    pageThemeDark: theme === ThemeEnum.DARK,
  })

  return (
    <div className={pageClasses}>
      <Container cssClass={Style.container}>
        <div className={Style.columns}>
          {props.menu && <Menu />}
          <div className={classNames(Style.mainPageWrapper, props.cssClassName)} ref={props.contentsRef}>
            {showInfoMessage.enabled && <div className={Style.message}>{message}</div>}
            <PageHeader pageTitle={props.pageTitle} />
            {props.contentWrapper ? <div className={pageContentsClasses}>{props.children}</div> : <>{props.children}</>}
          </div>
          {chatType !== null && chatType !== ChatTypeEnum.BAMBOO && <QuestionBox />}
        </div>
      </Container>
    </div>
  )
}

export default Page
