import { FC, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { ChatType, QuestionFile } from '../stores/AppStore'

import Style from '../styles/Home.module.sass'
import ChatSwitcher from '../components/ChatSwitcher'

const ChatGptHome: FC = () => {
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  if (appInsights) appInsights.trackPageView({ name: 'Secure ChatGPT Home' })

  useEffect(() => {
    setChatType(ChatTypeEnum.GPT)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  return (
    <Page menu contentWrapper>
      <div className={Style.pageContents}>
        <h1 className={Style.chatTitle}>Secure ChatGPT</h1>
        <ChatSwitcher activeChat={ChatTypeEnum.GPT} />
        <p>Secure ChatGPT is Aurecon's preferred method for using ChatGPT.</p>
        <p>
          SecureChatGPT allows you to use ChatGPT as you would normally do, while maintaining confidentiality and
          privacy, as your interactions are hosted within Aurecon's digital infrastructure.
        </p>

        <p>Ask a question below to get started.</p>

        <div className={Style.examples}>
          <h3>Navigation tips</h3>
          <Grid row>
            <div>
              <p>
                <Icon type='keyboard' outlined />
              </p>
            </div>
            <div className={Style.tip}>
              <p>
                Use ENTER to submit query
                <br />
                Use SHIFT + ENTER to add new line to your query
                <br />
                Use SHIFT + ARROW UP / DOWN to bring previous queries
              </p>
            </div>
          </Grid>

          <Grid row>
            <div>
              <p>
                <Icon type='post_add' outlined />
              </p>
            </div>
            <div className={Style.tip}>
              <p>Upload a document to give context to Secure ChatGPT</p>
            </div>
          </Grid>
        </div>
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatGptHome
