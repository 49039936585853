import { FC } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useMediaQuery } from 'react-responsive'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { AppTile } from '../components/AppTile'
import { useChangeChat } from '../hooks/useChangeChat'
import { AppPill } from '../components/AppPill'
import { AppColour } from '../enums/AppColourConstants'
import { AppShortcut } from '../components/AppShortcut'
import { UI_WIDTH_COLLAPSE } from '../config/config'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from '../hooks/useShowFeature'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'
import '@splidejs/react-splide/css/core'

import Style from '../styles/Home.module.sass'

const Home: FC = () => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo } = useChangeChat()
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const isMobile = useMediaQuery({ maxWidth: UI_WIDTH_COLLAPSE })

  if (appInsights) appInsights.trackPageView({ name: 'Home' })

  return (
    <Page menu contentWrapper>
      <h1>What can you do?</h1>

      {isMobile ? (
        <div className={Style.tiles}>
          <Splide aria-label='Chat Types' options={{ arrows: false, autoplay: true, loop: true, rewind: true }}>
            <SplideSlide>
              <AppTile
                AppIcon={RecallMenuIcon}
                content="Uses Aurecon's knowledge and expertise to quickly retrieve relevant information and answer your questions."
                onClick={openRecallChat}
              />
            </SplideSlide>
            <SplideSlide>
              <AppTile
                AppIcon={GPTMenuIcon}
                content='SecureChatGPT allows you to use ChatGPT as you would normally do, while maintaining confidentiality and privacy.'
                onClick={openChatGPT}
              />
            </SplideSlide>
            <SplideSlide>
              <AppTile
                AppIcon={WinWiseLogo}
                content='WinWise processes and analyses requests for proposals, tenders and other documents.'
                onClick={openWinWise}
              />
            </SplideSlide>
          </Splide>
        </div>
      ) : (
        <div className={Style.tiles}>
          <AppTile
            AppIcon={RecallMenuIcon}
            content="Uses Aurecon's knowledge and expertise to quickly retrieve relevant information and answer your questions."
            onClick={openRecallChat}
          />
          <AppTile
            AppIcon={GPTMenuIcon}
            content='SecureChatGPT allows you to use ChatGPT as you would normally do, while maintaining confidentiality and privacy.'
            onClick={openChatGPT}
          />
          <AppTile
            AppIcon={WinWiseLogo}
            content='WinWise processes and analyses requests for proposals, tenders and other documents.'
            onClick={openWinWise}
          />
        </div>
      )}

      <h1>What did you want to do today?</h1>

      <div className={Style.pills}>
        <AppPill
          content='Find something within Aurecon knowledge & expertise'
          onClick={openRecallChat}
          colour={AppColour.RECALL_CHAT}
        />
        <AppPill
          content='Ask a general question not related to Aurecon'
          onClick={openChatGPT}
          colour={AppColour.GPT_CHAT}
        />
        <AppPill content='Extract information from a document' onClick={openWinWise} colour={AppColour.WINWISE} />
        {showBambooChat.enabled && (
          <AppPill content='Extract structured information from a PDF' onClick={openBamboo} colour={AppColour.BAMBOO} />
        )}
      </div>

      <hr className={Style.divider} />

      <h2>Suggested Apps</h2>
      <div className={Style.shortcuts}>
        <AppShortcut AppIcon={RecallMenuIcon} content='Recall Chat' onClick={openRecallChat} />
        <AppShortcut AppIcon={GPTMenuIcon} content='Secure Chat GPT' onClick={openChatGPT} />
        <AppShortcut AppIcon={WinWiseLogo} content='Winwise' onClick={openWinWise} />
      </div>
    </Page>
  )
}

export default Home
