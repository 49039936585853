import { FC, useState } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'

import LimitedCharactersLeft from '../common/LimitedCharacters'
import { IBambooEntityModel } from '../../models/IBambooEntityModel'

import { v4 } from 'uuid'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import { BambooEntityModalFields, bambooEntityModalSchema } from '../../validators/bambooEntityModalValidator'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'

import Style from '../../styles/BambooEntityModal.module.sass'

interface IBambooEntityModalProps {
  open: boolean
  selectedEntity: IBambooEntityModel
  onClose: () => void
  onSave: (entity: IBambooEntityModel) => void
}

const EntityFields = {
  [BambooEntityModalFields.name]: {
    maxLength: 30,
  },
  [BambooEntityModalFields.description]: {
    maxLength: 1000,
  },
}

const BambooEntityModal: FC<IBambooEntityModalProps> = ({ open, onClose, onSave, selectedEntity }) => {
  const [entity, setEntity] = useState<IBambooEntityModel>(selectedEntity)
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)

  const onModalClosed = () => {
    onClose()
  }

  const onEntitySaved = () => {
    if (!entity) return
    onSave({
      ...entity,
      id: entity.id || v4(),
    })
    onModalClosed()
  }

  const validateEntity = (currentEntity: IBambooEntityModel) => {
    const validationResult = bambooEntityModalSchema().validate(
      { name: currentEntity.name, description: currentEntity.description },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const onValueSet = (fieldName: string, limit: number, value: string) => {
    const updatedEntity = { ...entity, [`${fieldName}`]: value.substring(0, limit) }
    const errors = validateEntity(updatedEntity)
    setErrors(errors)
    setEntity(updatedEntity)
  }

  return (
    <RecallModal
      isShowing={open}
      onClose={onModalClosed}
      chatType={ChatTypeEnum.BAMBOO}
      disabled={!entity.name || !!Object.keys(errors).length}
      onSave={onEntitySaved}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={entity.id ? 'Update' : 'Create'}
    >
      <div className={Style.contentWrapper}>
        <h2>{entity.id ? 'Edit Entity' : 'New Entity'}</h2>
        <p className={Style.entityDescription}>
          An Entity is something you’d like Bamboo to extract information about from the PDF. An example could be a
          “Character” from a book, or an “Asset” from a service manual.
        </p>
        <Grid row gap={12} cssClass={Style.form}>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder='Enter entity name'
              value={entity.name}
              onChange={(value) =>
                onValueSet(BambooEntityModalFields.name, EntityFields[BambooEntityModalFields.name].maxLength, value)
              }
              label='Entity Name'
              chatType={ChatTypeEnum.BAMBOO}
              error={errors.name?.[0]}
            />
            {!errors.name?.[0] && (
              <LimitedCharactersLeft
                maxLength={EntityFields[BambooEntityModalFields.name].maxLength}
                value={entity.name}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              multiline
              placeholder='Enter entity description'
              value={entity.description}
              onChange={(value) =>
                onValueSet(
                  BambooEntityModalFields.description,
                  EntityFields[BambooEntityModalFields.description].maxLength,
                  value,
                )
              }
              label='Description'
              multilineLimit={EntityFields[BambooEntityModalFields.description].maxLength}
              chatType={ChatTypeEnum.BAMBOO}
            />
          </Grid>
          <Grid item xs={12} cssClass={Style.infoRow}>
            <Icon type='info' cssClass={Style.infoIcon} outlined />
            <span>Use the description to help Bamboo understand more about your entity.</span>
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default BambooEntityModal
