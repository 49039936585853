import { ATTACHMENT_EXT, MAX_ATTACHMENT_SIZE, MAX_NO_ATTACHMENTS } from '../config/config'
import { AppColour } from './AppColourConstants'

export const ChatTypeEnum = {
  RECALL: 0,
  GPT: 1,
  CODE: 2,
  WINWISE: 4,
  BAMBOO: 5,
}

export const ChatTypeToPath = {
  [ChatTypeEnum.RECALL]: 'chat-recall',
  [ChatTypeEnum.GPT]: 'chat-gpt',
  [ChatTypeEnum.CODE]: 'chat-code',
  [ChatTypeEnum.BAMBOO]: 'chat-bamboo',
}

export const ChatTypeToLabel = {
  [ChatTypeEnum.RECALL]: { label: 'Recall', desktop: 'Recall', mobile: 'R', colour: AppColour.RECALL_CHAT },
  [ChatTypeEnum.GPT]: { label: 'Secure ChatGPT', desktop: 'GPT', mobile: 'G', colour: AppColour.GPT_CHAT },
  [ChatTypeEnum.CODE]: { label: 'Code Assistant', desktop: 'Code', mobile: 'C', colour: AppColour.CODE_CHAT },
  [ChatTypeEnum.WINWISE]: { label: 'Winwise', desktop: 'Winwise', mobile: 'W', colour: AppColour.WINWISE },
  [ChatTypeEnum.BAMBOO]: { label: 'Bamboo', desktop: 'Bamboo', mobile: 'B', colour: AppColour.BAMBOO },
}

export const ChatTypeStream = {
  [ChatTypeEnum.RECALL]: true,
  [ChatTypeEnum.GPT]: true,
  [ChatTypeEnum.CODE]: false,
}

export const ChatTypeActions = {
  loading: { clipboard: false, info: true, rating: false, feedback: false, edit: false },
  [ChatTypeEnum.RECALL]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
  [ChatTypeEnum.GPT]: { clipboard: true, info: true, rating: false, feedback: false, edit: false },
  [ChatTypeEnum.CODE]: { clipboard: true, info: true, rating: true, feedback: false, edit: false },
}

export const ChatTypeInput = {
  [ChatTypeEnum.RECALL]: { focus: false, file: false, fileDrop: false },
  [ChatTypeEnum.GPT]: { focus: false, file: true, fileDrop: true },
  [ChatTypeEnum.CODE]: { focus: false, file: false, fileDrop: false },
}

export const ChatTypeConfig = {
  [ChatTypeEnum.RECALL]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.GPT]: { maxSize: MAX_ATTACHMENT_SIZE, maxFiles: MAX_NO_ATTACHMENTS, extensions: ATTACHMENT_EXT },
  [ChatTypeEnum.CODE]: { maxSize: 0, maxFiles: 0, extensions: [] },
}

export const ChatTypeToPrompt = {
  [ChatTypeEnum.RECALL]: {
    start: 'Ask a question...',
    follow: 'Ask another question...',
    loading: 'Generating an answer...',
  },
  [ChatTypeEnum.GPT]: {
    start: 'Ask a question...',
    follow: 'Ask a follow up question...',
    loading: 'Generating an answer...',
  },
  [ChatTypeEnum.CODE]: {
    start: 'Ask a question...',
    follow: 'Ask another question...',
    loading: 'Generating an answer...',
  },
}
