import { FC, ReactNode } from 'react'
import { Grid } from '@aurecon-creative-technologies/styleguide'

import Style from '../../styles/common/ConfirmModal.module.sass'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'

interface ConfirmModalProps {
  title: string
  message: string | ReactNode
  open: boolean
  labelYes?: string
  labelClose?: string
  onClose: () => void
  onSave: () => void
  size?: 'small' | 'medium' | 'large'
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const { title, message, open, labelYes, labelClose, onClose, onSave, size } = props

  return (
    <RecallModal
      isShowing={open}
      onClose={onClose}
      chatType={ChatTypeEnum.BAMBOO}
      disabled={false}
      onSave={onSave}
      size={size ?? 'small'}
      labelYes={labelYes}
      labelNo={labelClose}
      hideCloseIcon={true}
    >
      <Grid row cssClass={Style.confirmModalContainer}>
        <Grid item xs={12} cell>
          <div className={Style.titleHolder}>
            <h2>{title}</h2>
          </div>
          <div className={Style.messageHolder}>{message}</div>
        </Grid>
      </Grid>
    </RecallModal>
  )
}

export default ConfirmModal
