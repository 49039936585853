import { FC } from 'react'
import { Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallDropdown.module.sass'
import classNames from 'classnames'

export interface IRecallDropdownProps {
  chatType: number
  open?: 'down' | 'up'
  label?: string
  placeholder?: string
  selectedItem?: string | number
  items: IDropdownItemProps[]
  onSelectItem?: (item: string | number) => void
  cssClass?: string
  disabled?: boolean
}

const RecallDropdown: FC<IRecallDropdownProps> = ({
  chatType,
  open,
  label,
  placeholder,
  selectedItem,
  items,
  onSelectItem,
  cssClass,
  disabled,
}) => {
  const chatTypeClasses = () => {
    return classNames(cssClass, {
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
    })
  }

  return (
    <Dropdown
      open={open}
      label={label}
      items={items}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
      cssClass={chatTypeClasses()}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default RecallDropdown
