import { getAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export interface ILoggedUser {
  department: string
}

export interface IAppVersion {
  version: string
}

export interface IFlagFilters {
  name: string
  parameters: {
    [y: string]: string
  }
}

export interface IFlagDetails {
  enabled: boolean
  client_filters?: IFlagFilters[]
}

export interface IAppFlags {
  [x: string]: IFlagDetails
}

const FAIL_VERSION_RESPONSE = { success: false, data: { version: '0.0.0' } }

export const getAppVersion = async (): Promise<IResponse<IAppVersion>> => {
  try {
    const response = await fetch('/v1/app/version')
    if (response.ok) return await response.json()
    else return FAIL_VERSION_RESPONSE
  } catch {
    return FAIL_VERSION_RESPONSE
  }
}

export const getAppFlags = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IAppFlags>> => getAsync('/v1/app/flags', request),
  [],
  null,
)

export const getLoggedUser = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ILoggedUser>> => getAsync('/v1/app/user', request),
  [],
  null,
)
