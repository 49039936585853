import { FC, useEffect, useRef, useState, useMemo } from 'react'
import Style from '../styles/ChatSwitcher.module.sass'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { useChangeChat } from '../hooks/useChangeChat'
import { ChatTypeEnum, ChatTypeToLabel } from '../enums/ChatTypeEnum'

export interface IChatSwitcherProps {
  activeChat: number
}

const ChatSwitcher: FC<IChatSwitcherProps> = ({ activeChat }) => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo } = useChangeChat()
  const containerRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef])

  const chatItems = useMemo(() => {
    return [
      { type: ChatTypeEnum.RECALL, label: ChatTypeToLabel[ChatTypeEnum.RECALL].label },
      { type: ChatTypeEnum.GPT, label: ChatTypeToLabel[ChatTypeEnum.GPT].label },
      { type: ChatTypeEnum.WINWISE, label: ChatTypeToLabel[ChatTypeEnum.WINWISE].label },
      { type: ChatTypeEnum.BAMBOO, label: ChatTypeToLabel[ChatTypeEnum.BAMBOO].label },
    ]
  }, [])

  const onClick = (openChat: number) => {
    switch (openChat) {
      case ChatTypeEnum.RECALL: {
        openRecallChat()
        break
      }
      case ChatTypeEnum.GPT: {
        openChatGPT()
        break
      }
      case ChatTypeEnum.WINWISE: {
        openWinWise(true)
        break
      }
      case ChatTypeEnum.BAMBOO: {
        openBamboo()
        break
      }
    }
  }

  const getChatConfig = () => {
    let config: {
      style: string
      label: string
    } | null = null

    switch (activeChat) {
      case ChatTypeEnum.RECALL: {
        config = {
          style: Style.recall,
          label: ChatTypeToLabel[ChatTypeEnum.RECALL].label,
        }
        break
      }
      case ChatTypeEnum.GPT: {
        config = {
          style: Style.chatGpt,
          label: ChatTypeToLabel[ChatTypeEnum.GPT].label,
        }
        break
      }
      case ChatTypeEnum.WINWISE: {
        config = {
          style: Style.winwise,
          label: ChatTypeToLabel[ChatTypeEnum.WINWISE].label,
        }
        break
      }
      case ChatTypeEnum.BAMBOO: {
        config = {
          style: Style.bamboo,
          label: ChatTypeToLabel[ChatTypeEnum.BAMBOO].label,
        }
        break
      }
    }

    return config
  }

  const chatConfig = getChatConfig()

  const chatItemClasses = (type: number) => {
    return classNames({
      [Style.item]: true,
      [Style.active]: activeChat === type,
    })
  }

  return (
    <div className={Style.chatSwitcherContainer} ref={containerRef}>
      <div className={classNames(Style.selectedChat, chatConfig?.style)}>
        <div role='none' className={Style.label} onClick={() => setExpanded(!expanded)}>
          <div className={classNames(Style.icon, chatConfig?.style)}></div>
          <span>{chatConfig?.label}</span>
        </div>
        <div className={Style.expandIcon}>
          <Icon type='expand_more' onClick={() => setExpanded(!expanded)} />
        </div>
      </div>

      {expanded && (
        <div className={Style.chatItemsContainer}>
          <div className={Style.chatItems}>
            {chatItems.map((m) => {
              return (
                <div role='none' key={m.type} className={chatItemClasses(m.type)} onClick={() => onClick(m.type)}>
                  {m.label}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatSwitcher
