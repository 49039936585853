import Joi from 'joi'
import { validateSpecialCharacters } from '../helpers/utils'

export const BambooEntityModalFields = {
  name: 'name',
  description: 'description',
}

export const bambooEntityModalSchema = () => {
  return Joi.object({
    [BambooEntityModalFields.name]: Joi.string()
      .trim()
      .min(1)
      .max(30)
      .required()
      .custom((text, helper) => {
        const validForSpecialChars = validateSpecialCharacters(text)
        if (!validForSpecialChars) {
          return helper.message({ custom: 'Cannot contain special character in Entity name' })
        }
        return true
      })
      .messages({ 'string.empty': 'Entity name is required' }),
    [BambooEntityModalFields.description]: Joi.string().allow('').max(1000),
  })
}
