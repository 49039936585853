import { FC, useRef } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'

import Style from '../styles/StaticPage.module.sass'

const TermsAndConditions: FC = () => {
  const contentContainerRef = useRef<HTMLDivElement>(null)

  if (appInsights) appInsights.trackPageView({ name: 'Terms And Conditions' })

  const handleBackToTop = () => {
    if (!contentContainerRef.current) return
    contentContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Page menu contentWrapper>
      <div className={Style.staticContent} ref={contentContainerRef}>
        <h1>
          Terms of use - Recall<sup>TM</sup>
        </h1>
        <ol className={Style.list}>
          <li>
            <span>OVERVIEW</span>
            <p>
              Recall is an interface to Aurecon's knowledge and expertise, that allows us to retrieve relevant
              information and answer user queries quickly and accurately. It is Aurecon’s first intelligent assistant,
              taking advantage of the recent rapid technological advances in artificial intelligence. <br />
              <br />
              Driven by Large Language Model (LLM) technology, Recall Chat uses Aurecon’s knowledge and expertise to
              answer Aurecon related queries. Other applications in the Recall platform include LLM’s developed by
              Aurecon for use within Aurecon, or readily available LLM’s made available under open-source licence, or
              through Microsoft Azure OpenAI, with general or specific use cases.
            </p>
            <ul className={Style.listSubLevel1}>
              <li>
                <span>1.1</span>Access
                <ul>
                  <li>
                    Recall is currently only available to Aurecon employees. Access should not be provided to any other
                    users without prior permission of Managing Principal – Digital.{' '}
                  </li>
                  <li>
                    Using Recall’s Secure ChatGPT is Aurecon’s preferred method of using ChatGPT, or any other generic
                    Generative AI solution, as it is an Aurecon-hosted digital environment, keeping all data securely
                    within our organisation.{' '}
                  </li>
                </ul>
              </li>
              <li>
                <span>1.2</span>Your responsibilities
                <ul className={Style.listSubLevel2}>
                  <li>
                    <span>1.2.1</span>When inputting information, you are responsible for:
                    <ul>
                      <li>The accuracy, relevance and completeness of the information submitted to Recall.</li>
                      <li>
                        <span>Following all existing Aurecon governance including (but not limited to): </span>
                        <ul>
                          <li>
                            Protecting Aurecon’s confidential information and meeting confidentiality obligations with
                            respect to our clients and our partners.{' '}
                          </li>
                          <li>
                            Protecting Aurecon’s intellectual property and respecting that of our clients, partners and
                            third parties.{' '}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>1.2.2</span>Regarding output:
                    <ul>
                      <li>
                        <span>
                          Users are responsible for the review and approval of any content generated by Recall, and all
                          AI applications within it.
                        </span>
                        <ul>
                          <li>
                            The limitations of generative artificial intelligence require careful review of the
                            information output to ensure it is appropriate for the context of the original query.
                          </li>
                          <li>
                            Users are responsible for ‘fact-checking’ any outputs. Recall generates information based on
                            referencing existing sources and documents, and the content generated does not guarantee
                            accuracy or factual information.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Ensure the output is coherent for the expected use such as formatting, terminology used and the
                        tone in which it is written.{' '}
                      </li>
                      <li>
                        Content generated by Recall must NEVER be used and/or incorporated into proposals, reports,
                        etc., without a thorough review and approval process being carried out.{' '}
                      </li>
                      <li>
                        Under our existing licence with OpenAI, the rights to the content generated in Recall are owned
                        by Aurecon.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>1.2.3</span>Existing policies and requirements Further to this, all existing IT policies and
                    contractual arrangements on document management, hosting, sharing, privacy and confidentiality apply
                    to the use of this system. <br /> All standards and expectations outlined in The Aurecon Ethos (Code
                    of Conduct) should be applied when using Recall.
                  </li>
                </ul>
              </li>
              <li>
                <span>1.3</span>Data handling
                <ul>
                  <li>
                    Your interactions with Recall are recorded in a secure database, however your identity is
                    anonymised.
                  </li>
                  <li>We cannot connect queries with a specific user identity.</li>
                  <li>
                    This data is retained indefinitely for the purpose of further application development and training.
                  </li>
                  <li>
                    Protected files, and information classified as Confidential, Restricted, or Highly Restricted data
                    should not be uploaded to Recall.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <span>WHO WE ARE AND HOW TO CONTACT US</span>
            <p>
              Recall is a web application (App) operated by Aurecon Australasia Pty Ltd (ABN 54 005 139 873) of Level 8,
              850 Collins Street, Docklands VIC 3008 (we, us, and our).
            </p>
            <p>
              To contact us, please email{' '}
              <a href='mailto:melbourne@aurecongroup.com' target='_blank' rel='noreferrer'>
                melbourne@aurecongroup.com
              </a>{' '}
              <b>OR</b> call +61 3 9975 3000
            </p>
          </li>
          <li>
            <span>BY USING OUR APP YOU ACCEPT THESE TERMS</span>
            <p>
              By using our App, you confirm that you accept these terms of use and that you agree to comply with them.
              If you do not agree to these terms, you must not use our App.
            </p>
          </li>
          <li>
            <span>PRIVACY</span>
            <p>
              Aurecon, its authorised Personnel and third-party service providers (on its behalf) collect, store and
              disclose Personal Information (as defined in the Privacy Act 1988) collected via the App, in accordance
              with Aurecon's Privacy Policy, which can be found here:{' '}
              <a href='https://www.aurecongroup.com/footer/privacy-policy' target='_blank' rel='noreferrer'>
                https://www.aurecongroup.com/footer/privacy-policy
              </a>
              .
            </p>
            <p>
              We also uphold your rights to privacy if you are based in the European Union, in accordance with the
              General Data Protection Regulation (EU) (<b>GDPR</b>).
            </p>
            <p>
              By using the App and by creating User Contributions, you give us permission to use your Personal
              Information in accordance with Aurecon's Privacy Policy. If you do not wish to provide Personal
              Information to us, then you do not have to do so. However, this may affect your use of this Site or any
              products and services offered on it.
            </p>
            <p>
              If at any stage, you would like your Personal Information or User Contributions removed, amended or
              deleted from the App, please email{' '}
              <a href='mailto:melbourne@aurecongroup.com' target='_blank' rel='noreferrer'>
                melbourne@aurecongroup.com
              </a>{' '}
              <b>OR</b> call +61 3 9975 3000.
            </p>
            <p>
              For transparency, we may use Personal Information collected or stored using the App for the following
              purposes:
            </p>
            <ul>
              <li>internal product review or support purposes only. (first & last name, occupation, email address)</li>
              <li>
                tracking user behaviour via an analytics tool (Smartlook) that is GDPR compliant. The tool masks any
                confidential user information.{' '}
                <a href='https://www.smartlook.com' target='_blank' rel='noreferrer'>
                  https://www.smartlook.com
                </a>
              </li>
              <li>We do not track user locations while using the App.</li>
            </ul>
          </li>
          <li>
            <span>COOKIES</span>
            <p>
              When you access the Aurecon Recall app, we may send a "cookie" (which is a small summary file containing a
              unique ID number) to your computer. This enables us to recognise your computer and greet you each time you
              visit our website without bothering you with a request to register. You must only consent once use the
              application.
            </p>
            <p>
              We also may use cookies to measure traffic patterns, to determine which areas of our website have been
              visited, and to measure transaction patterns in the aggregate. We use this to research our users' habits
              so that we can improve our online services. Our cookies do not collect Personal Information. If you do not
              wish to receive cookies, you can set your browser so that your computer does not accept them. We may log
              IP addresses (that is, the electronic addresses of computers connected to the Internet) to analyse trends,
              administer the website, track users' movements, and gather broad demographic information.
            </p>
          </li>
        </ol>
        <div className={Style.backToTopBtn}>
          <Button type='secondary' label='Back to top' onClick={handleBackToTop} />
        </div>
      </div>
    </Page>
  )
}

export default TermsAndConditions
